import React from "react";
import {Link} from "gatsby";
import Layout from "../components/layout"
import {getTranslatedFields, getCurrentLocale, getTranslatedValue} from "../helpers/fields";

const {getThemeSlug} = require("../helpers/slugs");

class Themes extends React.Component {
    render() {

        const themes = this.props.pageContext.data;

        const locale = getCurrentLocale(this);

        const translations = this.props.pageContext.translations;

        return (
            <Layout page={this} translations={translations} lang={locale} className='kunstwerken'
                    title={getTranslatedValue(this, translations.works_of_art)} includeMenu={true}>
                <h2>{getTranslatedValue(this, translations.select_theme)}</h2>

                <div className="wrapper">
                    {themes.map((theme, index) => {
                        return (
                            <article key={index}>
                                <Link to={getThemeSlug(theme.node, locale)}>
                                    <figure>
                                        <img src={theme.node.fieldValues.featuredimage.url}
                                             alt={theme.node.fieldValues.featuredimage.alt}/>
                                    </figure>
                                    <div className="text">
                                        <h3>{getTranslatedValue(this, theme.node.fieldValues.title)}</h3>
                                        <p>{getTranslatedValue(this, theme.node.fieldValues.description)}</p>
                                    </div>
                                    <span className="cta"></span>
                                </Link>
                            </article>
                        )
                    })}
                </div>
            </Layout>
        );
    }
}

export default Themes;
